import React, { useEffect, useRef, useState } from 'react';
import Guest from '@App/Layouts/GuestLayout';
import { InputLabel } from '@Components/InputLabel';
import { router, usePage } from '@inertiajs/react';
import { FieldValues, useForm } from 'react-hook-form';
import { LoginProps } from '@Pages/Auth/LoginType';

import jmsLogo from '@Assets/images/Logo/jms-logo.png';
import { InputTextField } from '@App/Components';
import Language from '@Components/Language';
import { ThemeButton } from '@Components/Form/ThemeButton';
import InputCheckboxButton from '@Components/Form/InputCheckboxButton';
import { Toast } from 'primereact/toast';
import { showNotificationToast } from '@Utils/Helpers';
import { ProgressBar } from "primereact/progressbar";
import { NO, YES } from "@Types/ApplicationConstants";

function Login() {
  const toast = useRef<Toast>(null);
  const { errors, flash } = usePage().props as LoginProps;

  const { control, handleSubmit } = useForm();

  function checkArrayValue(arr: string[] | undefined) {
    return !!arr && arr?.includes('1');
  }

  const [showProgressBar, setShowProgressBar] = useState<string>(NO);
  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      setShowProgressBar(event.persisted ? YES : NO);
      if (event.persisted) {
        location.reload();
      }
    }

    //popstate
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  });

  const submit = (validateState: FieldValues) => {
    const postToLogin = {
      ...validateState,
      remember: checkArrayValue(validateState?.remember) ?? false,
    };

    router.post(route('login'), postToLogin, {replace: true});
  };

  useEffect(() => {
    showNotificationToast(toast, errors, flash?.message);
  }, [errors, flash?.message]);

  if (showProgressBar === YES) {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>;
  }

  return (
    <Guest>
      <Toast ref={toast} position="bottom-left" />
      <div className="align-self-center mt-auto mb-auto">
        <form onSubmit={handleSubmit(submit)}>
          <div className="login-pages-panel card flex flex-column">
            <div className="text-center my-6">
              <img src={jmsLogo} alt="JMS Logo" height={66} />
            </div>
            <div className="input-panel flex flex-column px-3">
              <div className="p-inputgroup mb-2">
                <div className="p-inputgroup-addon">
                  <i className="pi pi-user" />
                </div>
                <div className="p-float-label">
                  <InputTextField
                    control={control}
                    dusk="username"
                    placeholder={Language.get('User Name')}
                    name="username"
                    className="border-1 border-800 px-2 py-0 login-input border-round-right w-full"
                    pt={{ root: { className: 'm-0 border-1 border-bluegray-200' } }}
                    validate={(value: string) =>
                      value?.length >= 3 || Language.get('User Name must be at least 3 letters')
                    }
                  />
                  <InputLabel htmlFor="password" className="pl-2">
                    {Language.get('User Name')}
                  </InputLabel>
                </div>
              </div>

              <div className="p-inputgroup mt-3 mb-6">
                <div className="p-inputgroup">
                  <div className="p-inputgroup-addon">
                    <i className="pi pi-lock" />
                  </div>
                  <div className="p-float-label">
                    <InputTextField
                      control={control}
                      dusk="password"
                      placeholder={Language.get('Password')}
                      name="password"
                      type="password"
                      className="border-1 border-800 px-2 py-0 login-input border-round-right"
                      pt={{ root: { className: 'm-0 border-1 border-bluegray-200' } }}
                      validate={(value: string) =>
                        value?.length >= 3 || Language.get('Password must be at least 3 letters')
                      }
                    />
                    <InputLabel htmlFor="password" className="pl-2">
                      {Language.get('Password')}
                    </InputLabel>
                  </div>
                </div>
              </div>
              <ThemeButton
                type="submit"
                data-dusk="login-button"
                style={{
                  background: 'var(--teal-700)',
                  color: 'white',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                  fontSize: '1.2rem',
                }}
              >
                {' '}
                {Language.get('Log in')}
              </ThemeButton>

              <div className="p-inputgroup mb-3 flex justify-content-between">
                <div className="field-checkbox">
                  <InputCheckboxButton
                    control={control}
                    dusk="remember"
                    name="remember"
                    checkboxClassName="text-700"
                    items={[
                      {
                        label: Language.get('Remember me'),
                        value: '1',
                      },
                    ]}
                  />
                </div>
                <div>{Language.get('Forgot Password')}</div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Guest>
  );
}

export default Login;
