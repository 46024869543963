import React from 'react';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import { IData } from '@Types/IData';
import { Control, Controller } from 'react-hook-form';
import { Checkbox, CheckboxPassThroughOptions } from 'primereact/checkbox';

interface InputCheckboxButtonProps {
  control: Control;
  label?: string;
  name: string;
  id?: string;
  dusk?: string;
  required?: boolean;
  readonly?: boolean;
  items: IData[];
  onChangeParent?: (e: RadioButtonChangeEvent) => void;
  checkboxClassName?: string;
  pt?: CheckboxPassThroughOptions;
}

function InputCheckboxButton(props: InputCheckboxButtonProps) {
  const {
    control,
    label,
    name,
    id = name,
    onChangeParent,
    dusk = id,
    items,
    required = false,
    readonly = false,
    checkboxClassName,
    pt,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error: errorController } }) => {
        const helperText = errorController ? errorController?.message : null;
        const handleChange = (event: any) => {
          const { value: checkboxValue, checked } = event.target;
          let updatedValues = Array.isArray(value) ? [...value] : [];

          if (checked) {
            if (!updatedValues.includes(checkboxValue)) {
              updatedValues.push(checkboxValue);
            }
          } else {
            updatedValues = updatedValues.filter((val) => val !== checkboxValue);
          }
          onChangeParent?.(event);
          onChange(updatedValues);
        };

        return (
          <>
            <div className={`${checkboxClassName ?? 'flex flex-column gap-3'}`}>
              {label && (
                <label htmlFor={id ?? name} className={`${helperText ? 'p-error' : ''}`}>
                  {label}
                  {required && <span className={`text-red-800 ${helperText ? 'p-invalid' : ''}`}>&nbsp;*</span>}
                </label>
              )}
              {items.map(
                (item) =>
                  (item?.visible ?? true) && (
                    <div key={item.value} className="flex align-items-center mb-2">
                      <Checkbox
                        inputId={item.value}
                        value={item.value}
                        onChange={handleChange}
                        checked={value?.includes(item.value)}
                        disabled={readonly}
                        className={checkboxClassName}
                        pt={pt}
                        data-dusk={dusk}
                      />
                      <label htmlFor={item.value} className={`ml-2 ${helperText ? 'p-error' : ''}`}>
                        {item.label}
                      </label>
                    </div>
                  )
              )}
            </div>
            {helperText && <small className="p-error">{helperText}</small>}
          </>
        );
      }}
    />
  );
}

export default InputCheckboxButton;
