import {IData} from "@Types/IData";
import {ResponseType} from "axios";

export const RESPONSE_TYPE_BLOB: ResponseType = 'blob'
export const AXIOS_RESPONSE_TYPE_BLOB: IData = {
  responseType: RESPONSE_TYPE_BLOB,
};

export const RESPONSE_TYPE_STREAM: ResponseType = 'stream'
export const AXIOS_RESPONSE_TYPE_STREAM: IData = {
  responseType: RESPONSE_TYPE_STREAM,
};

export const CREATE: string = 'create';
export const EDIT: string = 'edit';

export const YES: string = 'yes';
export const NO: string = 'no';

export const DEVICE_TYPE_SATELLITE: string = 'satellite';

export const ROUTE_STATUS_PENDING: string = 'pending';
export const ROUTE_STATUS_APPROVED: string = 'approved';
export const ROUTE_STATUS_STARTED: string = 'started';
export const ROUTE_STATUS_PAUSED: string = 'paused';

//Route Meta Column returns from backend
export const ROUTE_META_LOW_RISK: string = 'low_risk';
export const ROUTE_META_APPROVED: string = 'approved';
export const ROUTE_META_HIGH_RISK: string = 'high_risk';
export const MODULE_HIGH_RISK_APPROVALS: string = 'enable_high_risk_approvals';
export const ROUTE_META_ROUTE_WARNINGS: string = 'route_plan_warnings';

export const MODULE_ON_TRIP_START: string = 'enable_on_trip_start';
export const MODULE_ON_CHECKIN_POINT: string = 'enable_on_check_in_point';
export const MODULE_ON_TRIP_COMPLETE: string = 'enable_on_trip_complete';
export const MODULE_ROAD_SPEEDING : string= 'enable_road_speeds';
export const MODULE_ON_JOURNEY_NOTIFICATION: string = 'enable_journey_notification';
export const MODULE_PIVOTEL_TRACKING: string = 'enable_pivotel_tracking';
export const MODULE_GARMIN_TRACKING: string = 'enable_garmin_tracking';
export const MODULE_SUBMIT_SELECT_APPROVER: string = 'enable_submit_select_approver';
export const MODULE_ONE_OFF_JOURNEY: string = 'enable_one_off_journey';
export const MODULE_ROSTERING: string = 'enable_rostering';
export const MODULE_LONE_WORKER: string = 'enable_lone_worker';
export const MODULE_TRAVEL_DOCUMENTS: string = 'enable_travel_documents';
export const MODULE_EMPLOYEE_INDUCTION: string = 'enable_employee_induction';
