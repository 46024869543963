import React, { forwardRef } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { usePage } from '@inertiajs/react';

interface ThemeButtonProps extends ButtonProps {
  children?: any;
  themeButtonStyleProps?: any;
  onClick?: any;
  icon?: string;
}

const ThemeButton = forwardRef<HTMLButtonElement, ThemeButtonProps>((props, ref) => {
  const { type, children, themeButtonStyleProps, onClick, icon, style } = props;
  const { banner_color: bannerColor } = (usePage().props.auth as Auth).company?.settings || {};

  let themeButtonStyle = bannerColor ? { background: bannerColor } : themeButtonStyleProps;
  themeButtonStyle = {
    ...style,
    ...themeButtonStyle,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...props} ref={ref as any} type={type} style={themeButtonStyle} onClick={onClick} icon={icon}>
      {children}
    </Button>
  );
});

export default ThemeButton;
